import * as React from "react";
import { Helmet } from "react-helmet";

import Theme from "../components/theme";

// import hero from '../images/coaching.jpeg';

import "../styles/index.css";
import Footer from "../components/footer";
import Hero from "../components/hero";
import CookieConsent from "../components/cookieConsent";
import Buchung from "../components/buchung";

const IndexPage = () => {
  return (
    <Theme>
      <Helmet>
        <title>Angela Hollstein - Workshops</title>
        <meta
          name="keywords"
          content="coaching, freiburg, schweiz, deutschland, beratung, frauen, online, workshop, vortrag"
        />
      </Helmet>
      {/* Hero */}
      <Hero
        title="Workshops und Vorträge"
        heading="Setzen Sie dort an, wo es wirkt"
        image="2022/blumenzwo.jpg"
      />

      <div className="flex items-center justify-center">
        <div className="w-4/5 md:w-2/3 md:mt-20 z-10 mb-6 bg-white bg-opacity-70 rounded-lg p-10 flex flex-col gap-2">
          <p>
            In meinen Workshops stehe ich Ihnen mit meinen beruflichen
            Fachkenntnissen zu den vielfältigen Themen der Kinder- und
            Jugendhilfe zur Verfügung.
          </p>
          <p>
            Meine Schwerpunkte sind Workshops zu den Themen Kinderschutz und
            Sexualpädagogik, insbesondere in Kindertageseinrichtungen und bei
            freien Trägern der Kinder- und Jugendhilfe.
          </p>
          <p>
            Gerne begleite ich Sie bei der Erstellung eines sexualpädagogischen
            Konzepts mit dem Angebot von Workshops für Ihre Mitarbeitenden, über
            die konkrete Umsetzung für Ihre Einrichtung bis zur Gestaltung eines
            Elternabends zum Thema.
          </p>
          <p>
            Fragen Sie mich gerne für Inhouse-Veranstaltungen oder Vorträge an.
          </p>
        </div>
      </div>

      <Buchung />
      <Footer />

      <CookieConsent />
    </Theme>
  );
};

export default IndexPage;
